import v01HOME from './01HOME';
import v023D from './023D.json';
import v032D from './032D.json';
import v04paintings from './04paintings.json';
import v05biography from './05biography';
import v06about from './06about';
import v07contact from './07contact';

const pageMapper = {"v01HOME": v01HOME, "v023D": v023D, "v032D": v032D, "v04paintings": v04paintings, "v05biography": v05biography, "v06about": v06about, "v07contact": v07contact};

export {pageMapper};