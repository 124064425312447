        const about = () => {
            return (
                <main>
                    <p>This work is based on architectural deconstructions. Like in memories
or dreams, every part is reconstructed, leaving an impression of
unplanned reality. In some of the work there may be traces of human
presence, but they are all empty, or temporarily abandoned. Anything
could happen, but nothing does, besides the soundless shifting of
elements in a bare, changing and undefined volume. In this way
architecture transforms into anarchy of space. You can wander -not hide-
in these idle constructions which, in the end, only consist of a rhythm
between light and darkness.</p>
                </main>
            )
        }

        export default about;
