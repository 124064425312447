        const biography = () => {
            return (
                <main>
                    <p>2024</p>
<p>“Huis Clos”<br />
Pictura, Dordrecht, curator Ton Kraayeveld</p>
<p>2021</p>
<p>Vrouw of vos (Woman into Fox)<br />
Scenography<br /> Ulrike Quade Company, Amsterdam</p>
<p>Antoinette Reuten 1948-2020, Zomersalon<br />
What is Happening Here Gallery, Amsterdam</p>
<p>Publication in “The Point Magazine”, Chicago</p>
<p>2018</p>
<p>“Architectural Healing”, <br /> Cityscapes Foundation,
Koepelgevangenis Haarlem</p>
<p>2018</p>
<p>Zomer salon<br />
Reuten Galerie, Amsterdam</p>
<p>“6 for 6”<br />
Loods 6, Amsterdam</p>
<p>Cheryl Hazan Gallery<br />
New York</p>
<p>Rosa Spierhuis<br />
Laren</p>
<p>2017</p>
<p>“Time, Space &amp; Architecture”,<br />
Cityscapes Foundation, Amsterdam</p>
<p>“Perpetuum”<br />
Reuten Gallery, Amsterdam</p>
<p>2016</p>
<p>“Inner City”<br />
Cityscapes Foundation, Amsterdam</p>
<p>“Prelude Amsterdam Drawing”<br />
Reuten Galerie, Amsterdam</p>
<p>“Circular City”<br />
Cityscapes Foundation, Amsterdam</p>
<p>Amsterdam Artfair<br />
Reuten Gallery, Amsterdam</p>
<p>Duo expo with Daniël van Wel<br />
Reuten Gallery Amsterdam</p>
<p>2015</p>
<p>“Summer in the City/ Oh Brave new World”<br />
Cityscapes Gallery, Berlijn</p>
<p>TU, Delft</p>
<p>Art Rotterdam<br />
Van Nellefabriek, Reuten Galerie</p>
<p>2014</p>
<p>“Summer in the City”<br />
Cityscapes foundation, Amsterdam</p>
<p>Update#1<br />
Wiebengahal, Maastricht</p>
<p>KunstRAI, Amsterdam<br />
Reuten Galerie</p>
<p>“Invisible City”<br />
Cityscapes Foundation, Amsterdam</p>
<p>Art Rotterdam<br />
Van Nellefabriek, Reuten Galerie</p>
<p>Amphia Ziekenhuis, Breda</p>
<p>2013</p>
<p>“Magic Hour”<br />
The Ridder, A House for Contemporary Art, Maastricht</p>
<p>“Void”<br />
Reuten Galerie</p>
<p>“Fotofever”<br />
Galerie Pennings, Paris</p>
<p>“Huizenzee”<br />
Korrie Besems, Zwolle</p>
<p>Radboud Universty, Nijmegen</p>
<p>“More Dimensions”<br />
Cityscapes Foundation, Amsterdam</p>
<p>“King Size”<br />
Ampelhaus, Oranienbaum</p>
<p>“Inside Stories”<br />
Frans van Lent, Dordrecht</p>
<p>“Reframe Festival”<br />
Eindhoven</p>
<p>RAW artfair Rotterdam<br />
Cityscapes Foundation</p>
<p>Art Rotterdam<br />
Reuten Galerie</p>
<p>“Scenes of Destruction”<br />
solo, Reuten Galerie, Amsterdam</p>
<p>2012</p>
<p>“Hermetic City”<br />
Garage Rotterdam</p>
<p>“Lens Based”<br />
Reuten Galerie, Amsterdam</p>
<p>2011</p>
<p>Art Amsterdam<br />
Reuten Galerie</p>
<p>Duo, Reuten Galerie, Amsterdam</p>
<p>2010</p>
<p>Art Amsterdam<br />
Reuten Galerie</p>
<p>Groep exhibition<br />
Reuten Galerie, Maastricht</p>
<p>2009</p>
<p>“Huis Clos”<br />
Reuten Galerie, Amsterdam</p>
<p>“Portes Ouvertes”<br />
Reuten Galerie, Amsterdam</p>
<p>2007</p>
<p>“Stable 6”<br />
Galerie Mehrwert, Aken</p>
<p>2005</p>
<p>“For God’s Sake”<br />
B32, Maastricht</p>
<p>2003</p>
<p>“25 fps”<br />
Alter Schlachthof, Eupen, Aula Carolina, Aachen, Eifelmuseum
Blankenheim, “Pan Nox”, Pan Kunstforum Niederrhein, Emmerich am
Rhein</p>
<h3 id="collections">Collections</h3>
<p>Akzo Nobel Artfoundation. Collection AMC. Private Collections</p>
<h3 id="education">Education</h3>
<p>2000-2004<br />
Academie Beeldende Kunsten Maastricht</p>
<p>1987-1992<br />
Classical Music, Rotterdams Conservatorium</p>
                </main>
            )
        }

        export default biography;
