        const contact = () => {
            return (
                <main>
                    <h4 id="contact">contact</h4>
<p>Alexa Meyerman<br />
Kasteel Holtmeulenstraat 9<br />
6222 AV Maastricht<br />
<a href="mailto:alexameyerman@gmail.com"
class="email">alexameyerman@gmail.com</a></p>
<p>CITYSCAPES FOUNDATION<br />
<a href="https://cityscapesfoundation.nl/nl_NL/Artists/0/63/"
class="uri">https://cityscapesfoundation.nl/nl_NL/Artists/0/63/</a></p>
                </main>
            )
        }

        export default contact;
